import React from "react";
import Index from "./Index";
import "./../css/styles.css";


const Layout = () => {
		return (
			<>
				<div className="layout">
					<Index />
				</div>
			</>
		);
}

export default Layout;